@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

.contact {
    max-width: var(--block-max-width-plus-padding);
    padding: var(--block-padding, var(--block-padding-end) 0 0);
    margin: 0 auto;
    background-color: var(--colors-white);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.contactInner {
    padding: var(--block-padding-end) 0 0;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    grid-column: 1 / -1;

    @include rounded-corners($breakpoint: medium, $radius: 2rem 2rem 0 0);
}

.headline {
    padding-bottom: 2rem;
}

@include medium {
    .contact {
        background-color: inherit;

        --block-padding: var(--block-padding-end) 0;

        &.flushTop {
            padding-top: 0;
        }

        &.flushBottom {
            padding-bottom: 0;
        }
    }

    .contactInner {
        padding: var(--block-padding-end) 2.4rem;
    }
}

@include large {
    .contactInner {
        --column-width: calc((100% - (var(--gridContainer-gap) * 7)) / 8);

        padding: var(--block-padding-end) var(--column-width);
        grid-column: 1 / span 8;

        @include rounded-corners;
    }
}
