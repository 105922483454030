@import 'styles/breakpoints';
@import 'styles/mixins';

.card {
    margin: 0 -2.2rem;
    grid-column: 1 / -1;
}

.cardInner {
    display: flex;
    flex-direction: column;
    padding: 5.8rem 4.8rem 4.4rem;
    background-color: var(--colors-blue);
    color: var(--colors-cream);

    @include rounded-corners($breakpoint: medium, $radius: 0 0 2rem 2rem);
}

.headline {
    margin-top: 3rem;
    margin-bottom: 1.2rem;
}

.copy {
    margin-bottom: 4.8rem;
}

.iconWrapper {
    width: 6rem;
    height: 6rem;
}

.ctaWrapper {
    max-width: fit-content;
}

@include medium {
    .card {
        margin: 0;
    }

    .cardInner {
        padding: 5.8rem 10.2rem;
    }
}

@include large {
    .card {
        position: sticky;
        top: var(--block-padding-end);
        max-height: 50rem;
        margin: 0;
        margin-bottom: 6.1rem;
        grid-column: 9 / span 4;
    }

    .cardInner {
        padding: 10.6rem 4.8rem 9.6rem;

        @include rounded-corners;
    }

    .headline,
    .copy {
        max-width: 28rem;
    }

    .iconWrapper {
        width: 8rem;
        height: 8rem;
    }
}

@include xLarge {
    .card {
        margin-bottom: 4rem;
    }
}

@include xxLarge {
    .card {
        margin-bottom: 2rem;
    }
}
